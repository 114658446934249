<template>
    <div>
        <vs-button
            @click="toggleUrl()"
            class="rounded-full"
            :color=" path === '/app/coupons/list' ? 'primary' : 'white' "
            :class=" path === '/app/coupons/list' ? 'text-white' : 'text-primary' "
        >
            <feather-icon icon="PlusIcon" class="align-middle"/>
            Nuevo Cupón
        </vs-button>
        <router-view class="mt-10"></router-view>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
    created() {
        this.$store.dispatch('updatePageTitle', {
            title    : this.$t('Cupones'),
            subtitle : this.$t('Administra cupones'),
        });
        this.$store.dispatch('location/getAllCities')
    },

    methods : {
        toggleUrl() {
            if( this.path === '/app/coupons/list' ) this.$router.push({ path : '/app/coupons/new' });
            else this.$router.push({ path : '/app/coupons/list' });
        }

    },

    computed : {
        path() {
            return this.$route.path;
        }
    }

})
</script>
